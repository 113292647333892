var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"kuaijieche"}},[_c('div',{staticClass:"plans",class:{ 'is-mobile': !_vm.isPC }},[_c('div',{staticClass:"plan-wrapper-mask",class:{
        advance: _vm.carType == 'advance'
      }},[_c('div',{staticClass:"plan-wrapper",class:{
          advance: _vm.carType == 'advance'
        }},[_c('img',{staticClass:"plan-logo",staticStyle:{"width":"3.04rem","height":"2rem"},attrs:{"src":require("../../assets/plan-logo.png")}}),_c('div',{staticClass:"plan-label",class:{
            advance: _vm.carType == 'advance'
          }},[_vm._v(" ONLINE ")]),_vm._l((_vm.plans),function(plan,idx){return _c('div',{key:idx,staticClass:"plan",class:{
            advance: _vm.carType == 'advance'
          },on:{"click":function($event){return _vm.planSelect(idx)}}},[(plan.label)?_c('div',{staticClass:"label"},[_vm._v(_vm._s(plan.label))]):_vm._e(),_c('div',{staticClass:"unit"},[_vm._v(_vm._s(plan.name))]),_c('div',{staticClass:"price"},[_c('span',{staticClass:"currency"},[_vm._v("￥")]),_vm._v(" "+_vm._s((plan.price * plan.discount).toFixed(1))+" ")]),(plan.discount != 1)?_c('div',{staticClass:"discount"},[_c('div',{staticClass:"raw-price"},[_vm._v("￥"+_vm._s(plan.price))]),_vm._v(" 立省￥"+_vm._s((plan.price * (1 - plan.discount)).toFixed(1))+" ")]):_c('div',{staticClass:"discount",staticStyle:{"margin-bottom":"0.32rem"}})])})],2)])]),_c('div',{staticClass:"shangche"},[_c('div',{staticClass:"wrap",class:{ advance: _vm.carType == 'advance' },on:{"click":_vm.shangche}},[_vm._v(" 立即成为家庭会员 ")])]),_c('div',{staticClass:"warning"},[_c('svg',{staticClass:"icon",attrs:{"t":"1611487934591","viewBox":"0 0 1024 1024","version":"1.1","xmlns":"http://www.w3.org/2000/svg","p-id":"4157","width":"200","height":"200"}},[_c('path',{attrs:{"d":"M512 64C264.58 64 64 264.58 64 512s200.58 448 448 448 448-200.58 448-448S759.42 64 512 64z m0 752a36 36 0 1 1 36-36 36 36 0 0 1-36 36z m51.83-551.95L548 636a36 36 0 0 1-72 0l-15.83-371.95c-0.1-1.33-0.17-2.68-0.17-4.05a52 52 0 0 1 104 0c0 1.37-0.07 2.72-0.17 4.05z","p-id":"4158","fill":"#8a8a8a"}})]),_c('div',{staticClass:"desc"},[_vm._v("国服、儿童账号请勿购买")])]),_c('div',{staticClass:"divider"}),_c('loading',{attrs:{"display":_vm.showLoading}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }